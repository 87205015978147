import React, { useState } from "react";
import { Link } from "react-router-dom";
// Icons:
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

import "../stylesheets/Navbar.css";
import logo from "../images/Mnt AC transparent.png";

export default function Navbar() {
    const [isNavExpanded, setIsNavExpanded] = useState(false);

    return (
        <nav className="navigation bg-dark">
            <Link to="/">
                <img className="logo-img" src={logo} alt="logo" />
            </Link>
            <button
                className="hamburger"
                onClick={() => setIsNavExpanded(!isNavExpanded)}
            >
                {isNavExpanded ? <CloseIcon /> : <MenuIcon />}
            </button>

            <div
                // className="navigation-menu"
                className={
                    isNavExpanded
                        ? "navigation-menu expanded"
                        : "navigation-menu hidden"
                }
            >
                <ul onClick={() => setIsNavExpanded(false)}>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/about">About Us</Link>
                    </li>
                    <li>
                        <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                        <a
                            title="Instagram"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.instagram.com/alpinecoffeeinc/"
                        >
                            <InstagramIcon />
                        </a>
                    </li>
                    <li>
                        <a
                            title="Facebook"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/profile.php?id=100082095927891"
                        >
                            <FacebookIcon />
                        </a>
                    </li>
                    <li>
                        <a
                            title="Twitter"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://twitter.com/AlpineCoffeeInc"
                        >
                            <TwitterIcon />
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}
