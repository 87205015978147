import React from "react";
// Icons:
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";

import "../stylesheets/Contact.css";

export default function Contact() {
    return (
        <div>
            <h1 className="Contact-Banner">Where To Find Us</h1>
            <div className="container">
                <section className="Contact-block">
                    {/* Instagram */}
                    <div className="social-media">
                        <InstagramIcon
                            className="Contact-icon text-muted"
                            fontSize="large"
                        />
                        <a
                            title="Instagram"
                            className="Contact-links"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/alpinecoffee.vending/"
                        >
                            @alpinecoffeeinc
                        </a>
                    </div>

                    {/* Facebook */}
                    <div className="social-media">
                        <FacebookIcon
                            className="Contact-icon text-muted"
                            fontSize="large"
                        />
                        <a
                            title="Facebook"
                            className="Contact-links"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/alpine.coffee.vending/"
                        >
                            @alpine-coffee-vending
                        </a>
                    </div>

                    {/* Twitter */}
                    <div className="social-media">
                        <TwitterIcon
                            className="Contact-icon text-muted"
                            fontSize="large"
                        />
                        <a
                            title="Twitter"
                            className="Contact-links"
                            target="_blank"
                            rel="noreferrer"
                            href="https://twitter.com/AlpineCoffeeInc"
                        >
                            @AlpineCoffeeInc
                        </a>
                    </div>

                    {/* Tiktok */}
                    <div className="social-media">
                        <MusicNoteIcon
                            className="Contact-icon text-muted"
                            fontSize="large"
                        />
                        <a
                            title="Tiktok"
                            className="Contact-links"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.tiktok.com/@alpinecoffeeinc"
                        >
                            @alpinecoffeeinc
                        </a>
                    </div>

                    {/* Email */}
                    <div className="social-media">
                        <AlternateEmailIcon
                            className="Contact-icon text-muted"
                            fontSize="large"
                        />
                        <a
                            title="Email"
                            className="Contact-links"
                            target="_blank"
                            rel="noreferrer"
                            href="mailto:coffee@alpinecoffeeinc.com"
                        >
                            coffee@alpinecoffeeinc.com
                        </a>
                    </div>

                    {/* Phone */}
                    <div className="social-media">
                        <LocalPhoneRoundedIcon
                            className="Contact-icon text-muted"
                            fontSize="large"
                        />
                        <a
                            title="Phone"
                            className="Contact-links"
                            target="_blank"
                            rel="noreferrer"
                            href="tel:4708007515"
                        >
                            (470) 800-7515
                        </a>
                    </div>
                </section>

                <h1 className="map-title">Find a ☕ Stand Near You</h1>
                <div className="map-stretch">
                    {/* Google Map Code */}

                    <iframe
                        title="google-map"
                        id="google-map"
                        src="https://storage.googleapis.com/maps-solutions-d4ufpgqovg/locator-plus/7aah/locator-plus.html"
                        width="100%"
                        height="600px"
                        style={{ border: 0 }}
                        loading="lazy"
                        sandbox="allow-scripts"
                    ></iframe>
                </div>
            </div>
        </div>
    );
}
