import React, { Component } from "react";
import "../stylesheets/Footer.css";
import footLogo from "../images/Mnts.svg";

class Footer extends Component {
    render() {
        return (
            <footer className="Footer bg-dark">
                <div>
                    <img
                        className="Footer-img"
                        src={footLogo}
                        alt="mnt logo"
                        width="120"
                        height="96"
                    />
                    <span className="copyright text-muted">
                        &copy; Alpine Coffee 2023
                    </span>
                </div>
            </footer>
        );
    }
}

export default Footer;
