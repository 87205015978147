import React from "react";
import "../stylesheets/404.css";

// Images:
import cloud from "../images/404page/cloud_warmcasino.png";

export default function NoPage() {
    return (
        <main className="NoPage-bl_page404">
            <h1 className="NoPage-h1">Error 404. The page does not exist</h1>
            <p className="NoPage-p">
                Sorry! The page you are looking for can not be found. Perhaps
                the page you requested was moved or deleted. It is also possible
                that you made a small typo when entering the address. Go to the
                main page.
            </p>
            <div className="NoPage-bl_page404__wrapper">
                <img src={cloud} alt="explosion" />
                <div className="NoPage-bl_page404__el1"></div>
                <div className="NoPage-bl_page404__el2"></div>
                <div className="NoPage-bl_page404__el3"></div>
                <a className="NoPage-bl_page404__link" href="/">
                    go home
                </a>
            </div>
        </main>
    );
}
