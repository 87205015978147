import React from "react";
import MediaCard from "../components/MediaCard";
import "../stylesheets/About.css";
// Images:
import beansImg from "../images/beans.jpg";
import integrityImg from "../images/fourway-handshake.jpg";
import convenienceImg from "../images/cup-table-1.jpg";
import qualityImg from "../images/bean-bag.jpg";
import valueImg from "../images/flower-hands.jpg";
import faithImg from "../images/cross.jpg";
import innovationImg from "../images/lightbulb.jpg";
import sustainabilityImg from "../images/leaf.jpg";
import communityImg from "../images/together.jpg";

export default function About() {
    return (
        <div>
            <h1 className="About-Banner">Learn More About Us</h1>
            <div className="About-body container">
                <h1 className="About-header">Our Mission</h1>
                <div className="About-row">
                    <div className="About-imgHolder">
                        <img
                            className="About-img"
                            src={beansImg}
                            alt="coffee-beans"
                        />
                    </div>
                    <p className="About-par">
                        Alpine Coffee strives to deliver superior coffee
                        experiences that are both convenient and of the highest
                        quality, ultimately enhancing our customers' lives by
                        saving them time. We uphold our commitment to integrity
                        and prioritize our relationship with God, ensuring the
                        stability of our products and services. Our goal is to
                        add value to our customers' lives through exceptional
                        coffee and service.
                    </p>
                </div>
                <h1 className="About-header">Our Story</h1>
                <div className="About-row">
                    <div className="About-article">
                        <div className="About-article-col" id="article-top">
                            <p>
                                <span id="article-start">T</span>he story of
                                Alpine Coffee Inc. is one that is rooted in
                                determination, fueled by passion, and inspired
                                by a love for coffee. This journey began in
                                February 2022, amidst the chaos and uncertainty
                                surrounding the Ukrainian/Russian war. As
                                Ukrainian immigrants who had moved to the United
                                States in the early 2000s, we were in search of
                                the American dream. Over the past two decades,
                                we had dedicated ourselves to learning the
                                language, educating ourselves, and exploring the
                                world of entrepreneurship.
                            </p>
                            <p>
                                During this time, Julia and Mariella, a
                                mother-daughter duo who shared a special bond
                                over their love for coffee, noticed that the
                                quality and accessibility of coffee was a
                                problem that they faced. The war had made them
                                realize how precious our time on this earth is
                                and that we should not waste it stuck in a
                                drive-thru. They felt a sense of urgency to
                                create something that not only solved a problem,
                                but also provided an unforgettable experience.
                            </p>
                        </div>
                        <div className="About-article-col">
                            <p>
                                With the help of God, family, and friends,
                                Julia, a mother of six, and Mariella, a
                                full-time university student, poured their
                                energy into creating something unique. They
                                embraced their differences as Ukrainian women
                                and were determined to make a statement. Thus,
                                Alpine Coffee Inc. was born.
                            </p>
                            <p>
                                As a family-owned business, we believe in
                                creating an experience for our customers, not
                                just selling a product. We take pride in
                                offering a carefully crafted blend of coffee
                                that provides the perfect balance of flavor and
                                aroma. Using only the finest ingredients, such
                                as premium milk and a variety of delicious
                                syrups, we strive to make every cup of coffee an
                                unforgettable experience.
                            </p>
                            <p>
                                Our journey has been one of hard work,
                                dedication, and perseverance. We are humbled by
                                the opportunity to share our love for coffee
                                with the world and look forward to seeing where
                                this journey takes us. At Alpine Coffee Inc., we
                                are committed to supporting our local community,
                                giving back to those who have given us so much,
                                and making a positive impact in the world.
                            </p>
                        </div>
                    </div>
                </div>
                <h1 className="About-header">Core Values</h1>
                <div className="About-deck">
                    <MediaCard
                        cardImg={integrityImg}
                        cardName="Integrity"
                        cardText="We conduct ourselves with honesty,
                            transparency, and consistency."
                    />
                    <MediaCard
                        cardImg={convenienceImg}
                        cardName="Convenience"
                        cardText="We prioritize ease of use and
                            accessibility for our customers."
                    />
                    <MediaCard
                        cardImg={qualityImg}
                        cardName="Quality"
                        cardText="We are committed to providing high-quality coffee and service."
                    />
                    <MediaCard
                        cardImg={valueImg}
                        cardName="Value"
                        cardText="We strive to add value to people's lives through our products and experiences."
                    />
                    <MediaCard
                        cardImg={faithImg}
                        cardName="Faith"
                        cardText="We honor our relationship with God and prioritize spiritual growth."
                    />
                    <MediaCard
                        cardImg={innovationImg}
                        cardName="Innovation"
                        cardText="We are always seeking new and better ways to serve our customers."
                    />
                    <MediaCard
                        cardImg={sustainabilityImg}
                        cardName="Sustainability"
                        cardText="We are committed to reducing our environmental impact and promoting sustainability."
                    />
                    <MediaCard
                        cardImg={communityImg}
                        cardName="Community"
                        cardText="We prioritize building strong relationships and contributing positively to our communities."
                    />
                </div>
            </div>
        </div>
    );
}
