import React from "react";
import { Link } from "react-router-dom";
// Images:
import cupFlowersImg from "../images/cup-flowers-1.jpg";
import machineFrontImg from "../images/CoffeeMachineFront.jpg";
import machineSideImg from "../images/CoffeeMachineSide.jpg";

import "../stylesheets/Home.css";

export default function Home() {
    return (
        <main>
            <section className="Home-Banner">
                <h1 className="Home-Banner-header">Welcome to Alpine Coffee</h1>
                <p className="Home-Banner-paragraph">
                    Savor the Flavor, Embrace the Convenience!
                </p>
                <Link className="LearnMore-button" to="/about">
                    Learn More!
                </Link>
            </section>
            <section className="container">
                <div>
                    <div className="Home-content-1 Content-background bottom-gutter">
                        <div className="Home-img-container">
                            <img
                                id="home-img"
                                src={cupFlowersImg}
                                alt="person holding alpine coffee cup"
                            />
                        </div>
                        <div className="Home-text-container">
                            <h1 className="Home-header">
                                WHY PARTNER WITH US?
                            </h1>
                            <ul className="Home-list">
                                <li>
                                    Access to quality espresso to those close by
                                </li>
                                <li>
                                    We spend 20% of our revenue on marketing
                                </li>
                                <ul>
                                    <li>
                                        Market the locations of our machines
                                    </li>
                                    <li>
                                        And the businesses where our locations
                                        exist
                                    </li>
                                </ul>
                                <li>
                                    Visually pleasing coffee stand increases
                                    appeal and return of customers
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="Home-content-2 Content-background bottom-gutter">
                        <div className="Home-img-container">
                            <div>
                                <img
                                    id="home-img"
                                    src={machineFrontImg}
                                    alt="coffee machine front"
                                />
                            </div>
                            <div>
                                <img
                                    id="home-img"
                                    src={machineSideImg}
                                    alt="coffee machine side"
                                />
                            </div>
                        </div>
                        <div className="Home-text-container">
                            <h1 className="Home-header">WHAT WE DO:</h1>
                            <ul className="Home-list">
                                <li>Coffee vending service</li>
                                <li>
                                    Make quality, specialty espresso easily
                                    accessible
                                </li>
                                <li>Service our own vending machines daily</li>
                                <li>
                                    Add an appealing coffee spot to businesses
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="Home-content-3 Content-background bottom-gutter">
                        <h1 className="Home-header">ABOUT THE STANDS:</h1>
                        <div>
                            <ul className="Home-list Icon-list">
                                <li id="Icon-list-item">
                                    <div>
                                        <span
                                            className="material-symbols-outlined"
                                            id="stats-icon"
                                        >
                                            power
                                        </span>
                                    </div>
                                    <div>
                                        <p id="Icon-par">
                                            Our coffee vending stand takes up
                                            about 4x2 feet of space and only
                                            needs an outlet.
                                        </p>
                                    </div>
                                </li>
                                <li id="Icon-list-item">
                                    <div>
                                        <span
                                            className="material-symbols-outlined"
                                            id="stats-icon"
                                        >
                                            humidity_high
                                        </span>
                                    </div>
                                    <div>
                                        <p id="Icon-par">
                                            Water source is from a water
                                            reservoir within the stand.
                                        </p>
                                    </div>
                                </li>
                                <li id="Icon-list-item">
                                    <div>
                                        <span
                                            className="material-symbols-outlined"
                                            id="stats-icon"
                                        >
                                            coffee
                                        </span>
                                    </div>
                                    <div>
                                        <p id="Icon-par">
                                            Coffee is sold in 12oz cups and is
                                            very affordable.
                                        </p>
                                    </div>
                                </li>
                                <li id="Icon-list-item">
                                    <div>
                                        <span
                                            className="material-symbols-outlined"
                                            id="stats-icon"
                                        >
                                            total_dissolved_solids
                                        </span>
                                    </div>
                                    <div>
                                        <p id="Icon-par">
                                            Syrups are provided within our
                                            coffee stand at no additional
                                            charge.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
