import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function MediaCard(props) {
    const { cardName, cardText, cardImg } = props;
    return (
        <Card sx={{ maxWidth: 345, margin: "0.5rem" }}>
            <CardContent>
                <img
                    style={{
                        width: "100%",
                        height: "12rem",
                        objectFit: "cover",
                    }}
                    src={cardImg}
                    alt=""
                />
                <Typography gutterBottom variant="h5" component="div">
                    {cardName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {cardText}
                </Typography>
            </CardContent>
        </Card>
    );
}
