import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "../stylesheets/FAQ.css";

export default function FAQ() {
    return (
        <div>
            <h1 className="FAQ-Banner">Frequently Asked Questions</h1>
            <div className="FAQ-AccordionListContainer container">
                <Accordion className="FAQ-Accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className="FAQ-Question">
                            Q: What types of coffee can I get from Alpine Coffee
                            vending machines?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            A: Alpine Coffee vending machines offer a wide
                            variety of hot and iced coffee options, including
                            traditional drip coffee, espresso, cappuccino,
                            latte, and more. You can also choose from different
                            roast levels and flavors to suit your taste
                            preferences.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="FAQ-Accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className="FAQ-Question">
                            Q: How are the Alpine Coffee vending machines
                            maintained and cleaned?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            A: We take pride in keeping our machines in top
                            condition and ensuring that they're always clean and
                            ready to use. Our technicians perform regular
                            maintenance and cleaning tasks, including descaling
                            and sanitizing, to ensure the best possible coffee
                            experience for our customers.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="FAQ-Accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography className="FAQ-Question">
                            Q: How do I request a new Alpine Coffee vending
                            machine for my office?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            A: You can contact us directly through our website
                            or by phone to request a new vending machine for
                            your office. Our team will work with you to
                            determine the best location and setup for the
                            machine, and will provide ongoing support to ensure
                            its success.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="FAQ-Accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4a-content"
                        id="panel4a-header"
                    >
                        <Typography className="FAQ-Question">
                            Q: How do I report a problem with my Alpine Coffee
                            vending machine?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            A: If you experience any issues with your vending
                            machine, you can contact our customer support team
                            by phone or email. We'll work with you to
                            troubleshoot the problem and schedule a technician
                            visit if needed.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="FAQ-Accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5a-content"
                        id="panel5a-header"
                    >
                        <Typography className="FAQ-Question">
                            Q: Are Alpine Coffee vending machines eco-friendly?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            A: Yes! Our machines are designed with
                            sustainability in mind, using energy-efficient
                            components and recyclable materials wherever
                            possible. We also work with suppliers who share our
                            commitment to sustainability and ethical practices.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="FAQ-Accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel6a-content"
                        id="panel6a-header"
                    >
                        <Typography className="FAQ-Question">
                            Q: What are the benefits of having an Alpine Coffee
                            vending machine in my workplace?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            A: Having a coffee vending machine in your workplace
                            offers a number of benefits, including increased
                            productivity, improved employee satisfaction, and
                            cost savings compared to traditional coffee shops or
                            individual cups. Alpine Coffee vending machines also
                            provide a convenient and customizable coffee
                            experience for your team, without the hassle of
                            leaving the office.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="FAQ-Accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel7a-content"
                        id="panel7a-header"
                    >
                        <Typography className="FAQ-Question">
                            Q: How does the payment system work on Alpine Coffee
                            vending machines?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            A: Our vending machines offer touchless and card
                            payment options. You can simply tap your card or use
                            your phone to pay for your selected beverage,
                            without the need for cash or coins.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
}
