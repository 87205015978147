import React from "react";
import { Link } from "react-router-dom";
import "../stylesheets/Footer.css";

import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ContactsIcon from "@mui/icons-material/Contacts";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

export default function FooterSecondary() {
    return (
        <footer className="Footer FooterSec">
            <div className="FooterSec-links">
                <div className="FooterSec-nav">
                    <h3>Website Links:</h3>
                    <Link to="/" title="Home Page">
                        <HomeIcon fontSize="large" />
                    </Link>
                    <Link to="/about" title="About Page">
                        <InfoIcon fontSize="large" />
                    </Link>
                    <Link to="/faq" title="FAQ Page">
                        <QuestionAnswerIcon fontSize="large" />
                    </Link>
                    <Link to="/contact" title="Contacts Page">
                        <ContactsIcon fontSize="large" />
                    </Link>
                </div>
                <div className="FooterSec-social">
                    <h3>Social Media:</h3>
                    <a
                        title="Instagram"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/alpinecoffeeinc/"
                    >
                        <InstagramIcon fontSize="large" />
                    </a>
                    <a
                        title="Facebook"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/profile.php?id=100082095927891"
                    >
                        <FacebookIcon fontSize="large" />
                    </a>
                    <a
                        title="Twitter"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/AlpineCoffeeInc"
                    >
                        <TwitterIcon fontSize="large" />
                    </a>
                </div>
            </div>
        </footer>
    );
}
