import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import FooterSecondary from "../components/FooterSecondary";
import Footer from "../components/Footer";
import "../stylesheets/Main.css";

const Layout = () => {
    return (
        <>
            <Navbar />
            <Outlet />
            <FooterSecondary />
            <Footer />
        </>
    );
};

export default Layout;
